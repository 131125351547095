import { mdiImageFilterTiltShift, mdiQualityMedium, mdiThermometerLines } from '@mdi/js';
import { mapActions } from 'vuex'
import state from '../../auth/store/state'
import  { myMixin } from '../../../mixins';

export default {
    mixins: [myMixin],
    data: () => ({
    message: '',
    error: false,
    errors: [],
    loading: false,
    loadingGroup: false,
    loadingAmbience: false,
    renderComponent: true,
    workTime: null,
    showDates: false,
    showFormSearch: false,
    date: {
      start: null,
      end:  null,
      all:  true
    },
    group: null,
    ambience: null,
    itemDrop: null,
    validation: {},
    config: null,
    colors: ['error', 'success', 'primary', 'purple', 'pink', 'cyan', 'indigo', 'blue', 'teal', 'amber', 'blue-grey', 'amber'],
    }),
    
    created() {
      this.startDates();
    },
    computed: {
      students: function() {
        
        if(!this.group || !this.group.classes){
          return 0;
        }

        var max = this.group.classes[0].grid.students_join;

        this.group.classes.forEach(c => {

          if(c.grid.students_join > max){
            max = c.grid.students_join;
          }

        });

        return max;

      }
  },

  methods: {
      ...mapActions('group', ['ActionFindGroupById']),
      ...mapActions('ambience', ['ActionFindAmbiences', 'ActionSearchAmbiences']),
      ...mapActions('join', ['ActionFindJoinByGroupId', 'ActionFindJoinByAmbienceId']),
      ...mapActions('roomAllocation', ['ActionFindAllocations', 'ActionCreateAllocationClasses', 'ActionUpdateAllocation', 'ActionDeleteAllocation', 'ActionDeleteAllocationAll', 'ActionDeleteAllocationClasses']),
      

    startDates(){

      this.workTime   = state.acesso.periodo;
      this.date.all   = true;
      this.date.start = this.workTime.classes_start;
      this.date.end   = this.workTime.classes_end;
      
     
    },

    removeAllocation(item){

      if(this.loadingAmbience){
        return;
      }

      this.loadingAmbience = true;
      this.loadingGroup = true;
      
      var payload = {
          id:           item.id,
      };

      this.ActionDeleteAllocation(payload)
        .then((res) => {
          this.loadGroup(this.group);
          this.loadAmbience(this.ambience);
      }).catch((error) =>{
        this.error = true;
        this.message = error.message;
        this.errors = error.errors;
        this.loadingAmbience = false;
        this.loadingGroup = false;
      }).finally(() => {
        
      });

    },

    removeClasse(item){

      if(this.loadingAmbience){
        return;
      }

      this.loadingAmbience = true;
      this.loadingGroup = true;
      
      var payload = {
          items: [item.id],
      };

      this.ActionDeleteAllocationClasses(payload)
        .then((res) => {
          this.loadGroup(this.group);
          this.loadAmbience(this.ambience);
      }).catch((error) =>{
        this.error = true;
        this.message = error.message;
        this.errors = error.errors;
        this.loadingAmbience = false;
        this.loadingGroup = false;
      }).finally(() => {
        
      });

    },

    start(event){
      this.itemDrop = event;
    },

    endAmbience(){
      
      if(!this.ambience){
        return;
      }

      this.updateAmbience(this.ambience.id, this.itemDrop);

    },

    clearAmbience(){

      this.loadingAmbience = true;
      this.loadingGroup = true;

      var allocs = this.ambience.allocations.map(a => a.id);

      var payload = {
        items: allocs
      };

      this.ActionDeleteAllocationAll(payload)
        .then((res) => {
            this.loadGroup(this.group);
            this.loadAmbience(this.ambience);
      }).catch((error) =>{
        this.error = true;
        this.message = error.message;
        this.errors = error.errors;
        this.loadingAmbience = false;
        this.loadingGroup = false;
      }).finally(() => {
        
      });

    },

    clearGroup(){

      this.loadingAmbience = true;
      this.loadingGroup = true;
      var items = this.group.classes.map(a => a.id);

      var payload = {
        items: items
      };

      this.ActionDeleteAllocationClasses(payload)
        .then((res) => {
            this.loadGroup(this.group);
            this.loadAmbience(this.ambience);
      }).catch((error) =>{
        this.error = true;
        this.message = error.message;
        this.errors = error.errors;
        this.loadingAmbience = false;
        this.loadingGroup = false;
      }).finally(() => {
        
      });

    },

    updateAmbience(ambience_id, classes){

      if(!classes || classes.length == 0){
        return;
      }

      var start;
      var end;

      if(this.date.all){
        start = this.workTime.classes_start;
        end   = this.workTime.classes_end;
      }else{
        start = this.date.start;
        end   = this.workTime.classes_end;
      }

      this.loadingAmbience = true;
      this.loadingGroup = true;
      this.message = '';
      this.error = false;
      this.errors = [];
      
      let payload = {
        ambience_id:          ambience_id,
        classes:              classes,
        start_date:           start,
        end_date:             end,
        periodicity_type_id:  this.config.periodicity,
        config:               this.config
      };

      this.ActionCreateAllocationClasses(payload)
        .then((res) => {

            this.loadGroup(this.group);
            this.loadAmbience(this.ambience);
          
          
      }).catch((error) =>{
        
        this.error = true;
        this.message = error.message;
        this.errors = error.errors;
        this.loadingAmbience = false;
        this.loadingGroup = false;

      }).finally(() => {
        
      });



    },

    loadGroup(item){
      
      if(!item || !item.id){
        this.loadingGroup = false;
        return;
      }

      var payload = { 
        id: item.id,
        with: 'grids.discipline,grids.teacher,grids.classes.ambience.block'
      };

      this.loadingGroup = true;

      this.ActionFindGroupById(payload)
        .then((res) => {

          let classes = [];
          var group = res.data;

          group.grids.forEach(grid => {
            grid.classes.forEach(c => {
                c.grid = grid;
                c.grid.group = { id: group.id, name: group.name };
                classes.push(c);
            });
          });

          this.group = res.data;
          this.group.classes = classes;
          this.loadJoins(this.group, true);
          
        }).finally(() => {
          this.loadingGroup = false;
        });
    },

    loadAmbience(item){
      
      if(!item || !item.id){
        this.loadingAmbience = false;
        return;
      }

      this.loadingAmbience = true;
      
      var payload = { 
        find_columns: {
          id: item.id
        },
        with: 'block,floor,type,campus,allocations.grid.discipline,allocations.grid.group',
      };

      this.ActionFindAmbiences(payload)
        .then((res) => {

          this.ambience = res.data[0];
          this.loadJoins(this.ambience, false);
          
        }).finally(() => {
          this.loadingAmbience = false;
        });
    },

    loadJoins(item, byGroup){

      if(!item || !item.id){
        this.forceRerender();
        return;
      }
      
      var payload;

      if(byGroup){
        payload = {
          group_id: item.id,
          showAllCampuses: true,
          with: "grids.discipline,grids.group"
        }
      }else{
        payload = {
          ambience_id: item.id,
          showAllCampuses: true,
          with: "grids.discipline,grids.group"
        }
      }

      if(byGroup){
        
        this.ActionFindJoinByGroupId(payload).then((res) => {
          
          for (let i = 0; i < item.classes.length; i++) {
            
            if(item.classes[i].grid.join){
              var join = res.data.find(j => j.id == item.classes[i].grid.join.join_id);
              if(join){
                item.classes[i].grid.join.grids = join.grids;
                
              }
            }
  
          }
          
        }).finally(() => {
          this.forceRerender();
        });

      }else if(item.allocations){
        
        this.ActionFindJoinByAmbienceId(payload).then((res) => {
          
          for (let i = 0; i < item.allocations.length; i++) {
            
            if(item.allocations[i].grid.join){
              var join = res.data.find(j => j.id == item.allocations[i].grid.join.join_id);
              if(join){
                item.allocations[i].grid.join.grids = join.grids;
              }
            }
  
          }
          
        }).finally(() => {
          this.forceRerender();
        });
      }
    },

    forceRerender () {
      
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });

    },
    
    selectGroup(item){
      this.group = item;
      this.loadJoins(this.group, true);
    },
    
    selectAmbience(item){
      this.ambience = item;
      this.loadJoins(this.ambience, false);
    },

    searchAmbiences(item){

      var payload = {
        search: {
          group_id:           item.id,
          grid_id:            null,
          class_schedule_id:  null,
          empty:              true
        }
      };
      
      this.ActionSearchAmbiences(payload).then((res) => {
          
        
      }).finally(() => {
        
      });

    },

    getAmbienceDescription(ambience){
      return ambience.campus.initials + (ambience.block ?  ' - ' + ambience.block.initials : '') + ' - ' + ambience.name + (ambience.description ? ' (' + ambience.description + ')' : '');
    }

  }

}
